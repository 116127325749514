<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="handleAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="规则名称" v-model="searchParam.settingName" clearable />
        <v-select
          clearable
          :options="searchSettingTypeList"
          v-model="searchParam.settingType"
          @change="$refs.list.search()"
          label="规则类型"
        />
        <v-select
          clearable
          :options="settingOnList"
          v-model="searchParam.settingOn"
          @change="$refs.list.search()"
          label="启用状态"
        />
        <v-select
          clearable
          :options="limitedTypeList"
          v-model="searchParam.limitedType"
          @change="$refs.list.search()"
          label="限制类型"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          @click="handleEdit(scope.row)"
        />
        <v-button
          text="启用"
          type="text"
          v-if="scope.row.settingOn === 0"
          @click="changeSettingOn(scope.row, '1')"
        />
        <v-button
          text="停用"
          type="text"
          v-if="scope.row.settingOn === 1"
          @click="changeSettingOn(scope.row, '0')"
        />
      </template>
    </v-list>

    <v-dialog
      :title="dialogTitle"
      v-model="auditDialogFormVisible"
      width="50%"
      @confirm="doEdit"
    >
      <el-form label-width="100px">
        <el-form-item label="规则名称">
          <v-input
            v-model="tempObj.settingName"
            :width="400"
            :disabled="tempObj.isDefault === 1"
          />
        </el-form-item>
        <el-form-item label="规则类型">
          <v-select
            :options="settingTypeList"
            v-model="tempObj.settingType"
            :disabled="tempObj.isDefault === 1"
          />
        </el-form-item>
        <el-form-item label="扫码有效时间" v-if="tempObj.settingType == '1'">
          <v-date-picker
            v-model="tempObj.validDate"
            type="datetimerange"
          ></v-date-picker>
        </el-form-item>
        <el-form-item label="开启状态">
          <v-select
            :options="settingOnList"
            v-model="tempObj.settingOn"
            :disabled="tempObj.isDefault === 1"
          />
        </el-form-item>
        <el-form-item label="限制类型">
          <v-select
            :options="limitedTypeList"
            v-model="tempObj.limitedType"
            :disabled="tempObj.isDefault === 1"
          />
        </el-form-item>
        <el-form-item label="限制次数" v-if="tempObj.limitedType != '1'">
          <v-input
            v-model="tempObj.limitedCount"
            type="number"
            :disabled="tempObj.isDefault === 1"
          />
        </el-form-item>
        <el-form-item label="珍珠贝">
          <v-input v-model="tempObj.tokenPoint" type="number" />
        </el-form-item>
        <el-form-item label="明珠值">
          <v-input v-model="tempObj.integralPoint" type="number" />
        </el-form-item>
        <el-form-item label="扫码CODE" v-if="tempObj.settingCode">
          <v-input v-model="tempObj.settingCode" disabled />
        </el-form-item>
        <el-form-item label="二维码" v-if="tempObj.qrPic">
          <v-upload :imgUrls.sync="tempObj.qrPic" disabled />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  createSettingTypeOptions,
  editTitleMap,
  limitedTypeOptions,
  settingOnOptions,
  settingTypeOptions,
} from "./map";
import {
  auditUrl,
  getWishWallListUrl,
  pointSettingListUrl,
  changeSettingOnUrl,
  updatePointSettingUrl,
  addPointSettingUrl,
  pointSettingDetailUrl,
} from "./api";

export default {
  name: "bonusPointSettingList",
  data() {
    return {
      searchSettingTypeList: settingTypeOptions,
      settingTypeList: settingTypeOptions,
      createSettingTypeList: createSettingTypeOptions,
      limitedTypeList: limitedTypeOptions,
      settingOnList: settingOnOptions,
      searchParam: {
        settingType: undefined,
        settingName: undefined,
        settingOn: undefined,
        limitedType: undefined,
      },
      communitList: [],
      tableUrl: pointSettingListUrl,
      headers: [
        {
          prop: "settingType",
          label: "规则类型",
          align: "center",
          formatter: (row, prop) => {
            for (let i = 0; i < settingTypeOptions.length; i++) {
              if (settingTypeOptions[i].value == row.settingType) {
                return settingTypeOptions[i].label;
              }
            }
          },
        },
        {
          prop: "settingName",
          label: "规则名称",
        },
        {
          prop: "settingBegintime",
          label: "扫码有效开始时间",
        },
        {
          prop: "settingEndtime",
          label: "扫码有效结束时间",
        },
        {
          prop: "tokenPoint",
          label: "积分",
        },
        {
          prop: "limitedType",
          label: "限制类型",
          align: "center",
          formatter: (row, prop) => {
            for (let i = 0; i < limitedTypeOptions.length; i++) {
              if (limitedTypeOptions[i].value == row.limitedType) {
                return limitedTypeOptions[i].label;
              }
            }
          },
        },
        {
          prop: "limitedCount",
          label: "限制次数",
        },
        // {
        //   prop: "settingCode",
        //   label: "扫码CODE",
        //   formatter: (row, prop) => {
        //     if (row.settingType == '2') {
        //       return null
        //     }
        //   },
        // },
        // {
        //   prop: "qrPic",
        //   label: "二维码图片",
        //   // formatter: (row, prop) => createImgVNode(this, row, prop),
        //   formatter: (row, prop) => {
        //     if (row.settingType == '2') {
        //       return null
        //     } else {
        //       createImgVNode(this, row, prop)
        //     }
        //   },
        // },
        {
          prop: "settingOn",
          label: "启用状态",
          align: "center",
          formatter: (row, prop) => {
            for (let i = 0; i < settingOnOptions.length; i++) {
              if (settingOnOptions[i].value == row.settingOn) {
                return settingOnOptions[i].label;
              }
            }
          },
        },

        {
          prop: "insertTime",
          label: "创建时间",
        },
        {
          prop: "updateTime",
          label: "更新时间",
        },
      ],

      auditDialogFormVisible: false,
      dialogTitle: null,
      tempObj: {
        id: null,
        settingType: null,
        isDefault: null,
        settingName: null,
        validDate: [],
        settingBegintime: null,
        settingEndtime: null,
        tokenPoint: null,
        integralPoint: null,
        limitedType: null,
        limitedCount: null,
        settingCode: null,
        qrPic: null,
        updateUser: this.$store.state.app.userInfo.userId,
      },
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    handleAdd() {
      this.dialogTitle = editTitleMap.create;
      this.settingTypeList = createSettingTypeOptions;
      this.tempObj = {
        settingId: null,
        settingOn: "0",
        settingType: null,
        settingName: null,
        validDate: [],
        settingBegintime: null,
        settingEndtime: null,
        tokenPoint: null,
        integralPoint: null,
        limitedType: null,
        limitedCount: null,
        settingCode: null,
        qrPic: null,
        updateUser: this.$store.state.app.userInfo.userId,
      };

      this.auditDialogFormVisible = true;
    },
    handleEdit(data) {
      this.$axios
        .post(`${pointSettingDetailUrl}`, null, {
          params: {
            settingId: data.settingId,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.tempObj = res.data;
            if (this.tempObj.isDefault === 1) {
              this.settingTypeList = settingTypeOptions;
            } else {
              this.settingTypeList = createSettingTypeOptions;
            }
            this.tempObj = Object.assign(this.tempObj, {
              updateUser: this.$store.state.app.userInfo.userId,
            });
            this.tempObj.settingType = this.tempObj.settingType.toString();
            this.tempObj.settingOn = this.tempObj.settingOn.toString();
            this.tempObj.limitedType = this.tempObj.limitedType.toString();

            if (this.tempObj.settingBegintime && this.tempObj.settingEndtime) {
              this.tempObj.validDate = [
                new Date(this.tempObj.settingBegintime),
                new Date(this.tempObj.settingEndtime),
              ];
            }
            this.dialogTitle = editTitleMap.update;
            this.auditDialogFormVisible = true;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    doEdit() {
      if (!this.tempObj.settingName || this.tempObj.settingName == "") {
        this.$message.error("请输入规则名称");
        return;
      }
      if (!this.tempObj.settingType || this.tempObj.settingType == "") {
        this.$message.error("请选择规则类型");
        return;
      }

      if (this.tempObj.settingType == "1") {
        if (!this.tempObj.validDate || this.tempObj.validDate.length < 2) {
          this.$message.error("请选择扫码有效时间");
          return;
        }
      }
      if (!this.tempObj.limitedType || this.tempObj.limitedType == "") {
        this.$message.error("请选择限制类型");
        return;
      }
      if (this.tempObj.limitedType != "1") {
        if (!this.tempObj.limitedCount || this.tempObj.limitedCount == "") {
          this.$message.error("请输入限制次数");
          return;
        }
      }
      if (!this.tempObj.tokenPoint || this.tempObj.tokenPoint == "") {
        this.$message.error("请输入珍珠贝");
        return;
      }

      if (!this.tempObj.integralPoint || this.tempObj.integralPoint == "") {
        this.$message.error("请输入明珠值");
        return;
      }
      if (this.tempObj.validDate && this.tempObj.validDate.length > 1) {
        this.tempObj.settingBegintime = this.tempObj.validDate[0].format(
          "yyyy-MM-dd HH:mm:ss"
        );
        this.tempObj.settingEndtime = this.tempObj.validDate[1].format(
          "yyyy-MM-dd HH:mm:ss"
        );
      }

      let params = {
        limitedCount: this.tempObj.limitedCount,
        limitedType: this.tempObj.limitedType,
        tokenPoint: this.tempObj.tokenPoint,
        integralPoint: this.tempObj.integralPoint,
        qrPic: this.tempObj.qrPic,
        settingBegintime: this.tempObj.settingBegintime,
        settingCode: this.tempObj.settingCode,
        settingEndtime: this.tempObj.settingEndtime,
        settingId: this.tempObj.settingId,
        settingName: this.tempObj.settingName,
        settingOn: this.tempObj.settingOn,
        settingType: this.tempObj.settingType,
        updateUser: this.tempObj.updateUser,
      };

      this.$axios
        .post(
          `${
            this.tempObj.settingId ? updatePointSettingUrl : addPointSettingUrl
          }`,
          params
        )
        .then((res) => {
          if (res.code === 200) {
            this.auditDialogFormVisible = false;
            this.$message.success("操作成功！");
            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeSettingOn(data, settingOn) {
      let params = {
        settingId: data.settingId,
        updateUser: this.$store.state.app.userInfo.userId,
        settingOn: settingOn,
      };
      this.$axios
        .post(`${changeSettingOnUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功！");
            this.$refs.list.search();
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
